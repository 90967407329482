import { SegmentEvents } from '~/enums/segment-events'
import { segmentTrack, trackRvEvent } from '~/lib/tracking'
import type { Rv } from '~/types/api'

export const trackRentRvClicked = (config: any) => {
  segmentTrack(SegmentEvents.RentRvClicked, {
    isSeller: false
  })
}

export const trackDealerMessageClicked = (
  data: {
    rv: Rv
    cta: string | undefined
  }
) => {
  trackRvEvent(SegmentEvents.DealerMessageClicked, data.rv, {
    cta: data.cta,
  })
}

export const trackMessageSentSuccessfully = (data: { rvId: string }) => {
  segmentTrack(SegmentEvents.DealerMessageSuccessful, {
    rvId: data.rvId
  })
}

export const trackListingClicked = (data: { rv: Rv; cta: string | undefined }) => {
  trackRvEvent(SegmentEvents.ListingClicked, data.rv, {
    distanceKm: data.rv.distance,
    cta: data.cta
  })
}
